import { createRouter, createWebHistory } from 'vue-router';
import decodeJWT from '@/composables/decodeJWT';

import store from '../store';

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/auth/login_boxed.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/index.vue'),
        meta: { requiresAuth: true, role: 'Admin' },
    },
    {
        path: '/home-recreador',
        name: 'HomeRecreador',
        component: () => import('../views/index2.vue'),
        meta: { requiresAuth: true, role: 'Recreador' },
    },
    // Admin --------------------------
    {
        path: '/clientes',
        name: 'Clientes',
        component: () => import('../views/clientes/cliente.vue'),
        meta: { requiresAuth: true, role: 'Admin' },
    },
    {
        path: '/cliente/detalhes/:id',
        name: 'clienteAdminDetalhes',
        component: () => import('../views/clientes/clienteDetalhes.vue'),
        meta: { requiresAuth: true, role: 'Admin' },
    },
    {
        path: '/criancas',
        name: 'criancas',
        component: () => import('../views/criancas/criancas.vue'),
        meta: { requiresAuth: true, role: 'Admin' },
    },
    {
        path: '/eventos',
        name: 'Eventos',
        component: () => import('../views/eventos/eventos.vue'),
        meta: { requiresAuth: true, role: 'Admin' },
    },
    {
        path: '/eventos/Detalhes/:id',
        name: 'EventoAdminDetalhes',
        component: () => import('../views/eventos/EventoAdminDetalhes.vue'),
        meta: { requiresAuth: true, role: 'Admin' },
    },
    {
        path: '/recreadores',
        name: 'Recreadores',
        component: () => import('../views/recreadores/recreadores.vue'),
        meta: { requiresAuth: true, role: 'Admin' },
    },
    {
        path: '/recreador/detalhes/:id',
        name: 'RecreadorAdminDetalhes',
        component: () => import('../views/recreadores/RecreadorDetalhes.vue'),
        meta: { requiresAuth: true, role: 'Admin' },
    },
    {
        path: '/usuarios',
        name: 'Usuarios',
        component: () => import('../views/usuarios/Usuarios.vue'),
        meta: { requiresAuth: true, role: 'Admin' },
    },
    // Recreadores --------------------
    {
        path: '/evento/:id',
        name: 'EventoDetalhes',
        component: () => import('../views/eventos/EventoDetalhes.vue'),
        meta: { requiresAuth: true, role: 'Recreador' },
    },
    // ERROS ---------------------------
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "pages-error404" */ '../views/pages/error404.vue'),
        meta: { layout: 'auth' },
    },
];

const router = new createRouter({
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.layout && to.meta.layout === 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }

    if (to.meta.requiresAuth) {
        if (localStorage.getItem('token')) {
            const decodedToken = decodeJWT();
            try {
                const userRole = decodedToken['role'];

                if (to.meta.role) {
                    if (userRole === to.meta.role) {
                        next();
                    } else {
                        if (userRole == 'Admin') {
                            next({ path: '/' });
                        } else {
                            next({ path: '/home-recreador' });
                        }
                    }
                } else {
                    next();
                }
            } catch (error) {
                next({ path: '/login' });
            }
        } else {
            next({ path: '/login' });
        }
    } else {
        next();
    }
});

export default router;


